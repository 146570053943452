<template>
  <flash-alert v-model:show="flashAlert.show" :level="flashAlert.level">
    <span v-html="flashAlert.msg"></span>
  </flash-alert>
  <loading-overlay
    :active="isLoading"
    :is-full-page="fullPage"
    :loader="loader"
  />
</template>

<script>
import axios from "axios";
import { misc } from "@/mixins";
import { FlashAlert } from "@/components";
import { mapMutations, mapActions } from "vuex";

export default {
  name: "ESLogin",
  mixins: [misc],
  data() {
    return {
      isLoading: false,
      fullPage: true,
      loader: "bars",
    };
  },
  components: {
    FlashAlert,
  },
  mounted() {
    this.isLoading = true;
    this.doLogin();
  },
  methods: {
    ...mapMutations([
      "clearCartMeta",
      "clearCart",
      "clearQuote",
      "storeUserDetails",
      "storeQuote",
    ]),
    ...mapActions(["logout", "fetchCartAndMeta", "addToQuoteBulk"]),

    doLogin() {
      const customer_code = this.$route.params.customer_code;
      if (!customer_code) {
        this.showFlashAlert("Customer code does not exist", "error");
        return;
      }

      this.doLogout()
        .then(() => {
          return axios.post(
            `${process.env.VUE_APP_API_BASE}auth/loginForCustomerESales`,
            {
              customer_id: customer_code,
            },
            {
              headers: {
                "Content-type": "application/json",
                "Access-Control-Allow-Origin": "*",
              },
            }
          );
        })
        .then((response) => {
          if (response.data.error == true) {
            this.showFlashAlert(response.data.message);
          } else {
            let customer = response.data.data.customer;

            localStorage.setItem("token", response.data.data.token);

            this.storeUserDetails({
              name: customer.first_name + " " + customer.last_name,
              fname: customer.first_name,
              lname: customer.last_name,
              company_name: customer.company_name,
              company_code: customer.company_code,
              email: customer.email,
              branch_id: customer.branch_id,
              is_sales_rep: 0,
              is_e_sales: 1,
              can_enroll_loyalty: 0,
              is_enrolled_loyalty: 0,
              loyalty_status: 0,
            });
          }
        })
        .then(() => this.emitter.emit("updateMegamenu"))
        .then(() => this.addToQuoteBulk())
        .then(() => {
          this.emitter.emit("showLoader");
          return axios
            .post(
              `${process.env.VUE_APP_API_BASE}auth/getPendingQuotes`,
              {},
              {
                headers: {
                  "Content-type": "application/json",
                  Authorization: "Bearer " + localStorage.getItem("token"),
                },
              }
            )
            .then(({ data }) => {
              data.products.forEach((prod) => {
                this.storeQuote({
                  quant: prod.quantity,
                  prodId: prod.product_id,
                  unitId: prod.unit_id,
                  prodImage: prod.image,
                  prodName: prod.name,
                  unit: prod.unit,
                  prodSeo: prod.seo_url,
                  prodTypeId: prod.product_type_id,
                });
              });
            });
        })
        .then(() => this.fetchCartAndMeta())
        .then(() => this.emitter.emit("updatePrograms"))
        .then(() => this.emitter.emit("updateSpecialProgram"))
        .then(() => this.emitter.emit("getTopBanners"))
        .then(() => this.emitter.emit("getBottomBanners"))
        .then(() => this.emitter.emit("getPopups"))
        .then(() => this.$router.push({ name: "Shop" }))
        .catch((response) => {
          if (!("validation" in response)) {
            if (response.data) {
              this.showFlashAlert(response.data.message);
            }
          }
        })
        .finally(() => this.emitter.emit("hideLoader"));
    },

    doLogout() {
      if (this.isLoggedIn) {
        return this.logout().then(() => {
          this.clearCartMeta();
          this.clearCart();
          this.clearQuote();
        });
      } else {
        return Promise.resolve();
      }
    },
  },
};
</script>